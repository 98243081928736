import React from 'react';
import './BoxSection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const BoxSection = ({
    id
}) => {
    return (
        <div id={id} className="box-section section-padding bg-pink">
            <Container>
                <Row className='mb-30'>
                    <Col md="12">
                        <span className='title-meta'>Wedding</span>
                        <h2 className="title">When & Where</h2>
                    </Col>
                </Row>
                <Row className='box-container bg-img'>
                    <Col md="6" className='item-box'>
                        <h2 className='number'>01</h2>
                        <h3 className='box-title'>Welcome Party</h3>
                        <p>Viernes, 21 de marzo 2025</p>
                        <p>
                            5:00pm - Welcome party
                        </p>
                        <p>
                            ¡Amigos! Si están por aquí, vengan a echarse unos tragos, disfrutar de comida mexicana y buena música. ¡Nos vemos!
                            {/* <i className='ti-location-pin'>direccion</i> */}
                        </p>
                        <p>
                            Hotel Real de chapala
                            <br />
                            <i className='location ti-location-pin' /> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
                        </p>

                    </Col>
                    <Col md="6" className='item-box'>
                        <h2 className='number'>02</h2>
                        <h3 className='box-title'>Ceremonia</h3>

                        <p>Sábado, 22 de marzo 2025</p>
                        <p>
                            4:00pm - Ceremonia
                        </p>
                        <p>
                            Templo San Jose de la floresta
                            <br />
                            <i className='location ti-location-pin' /> P.º del Mirador 36, Fracc. La Floresta, 45920 Ajijic, Jal., México
                        </p>
                        <p>
                            6:00pm - Recepción
                        </p>
                        <p>
                            Nimue
                            <br />
                            <i className='location ti-location-pin' /> P.º de La Huerta No 57, Col. La Floresta, 45920 Ajijic, Jal., México
                        </p>
                    </Col>
                    {/* <Col md="3" className='item-box'>
                        <h2 className='number'>03</h2>
                        <p>Sábado, 22 de marzo 2025</p>
                        <p>
                            6:00pm - Recepción
                        </p>
                        <p>
                            Nimue
                            <br />
                            <i className='location ti-location-pin' /> P.º de La Huerta No 57, Col. La Floresta, 45920 Ajijic, Jal., México
                        </p>
                    </Col> */}
                    {/* <Col md="3" className='item-box'>
                        <h2 className='number'>03</h2>
                        <h3 className='box-title'>Tornaboda</h3>

                        <p>Domingo, 23 de marzo 2025</p>
                        <p>
                            12:00pm - 4. Tornaboda
                        </p>
                        <p>
                            Hotel Real de chapala
                            <br />
                            <i className='location ti-location-pin' /> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
                        </p>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default BoxSection;